import { createStackNavigator } from '@react-navigation/stack';
import PrivacyPolicy from "../screens/PrivacyPolicy";
import {RootStackParams} from "./types";
import {NavigationContainer} from "@react-navigation/native";
import LinkingConfiguration from "./LinkingConfiguration";

const RootStack = createStackNavigator<RootStackParams>();

export default function RootStackNavigator() {

    return(
        <NavigationContainer linking={LinkingConfiguration}>
            <RootStack.Navigator>
                <RootStack.Screen name={"Privacy"} component={PrivacyPolicy} options={{headerTitle: "Privacy Terms"}} />
            </RootStack.Navigator>
        </NavigationContainer>
    )
}
