import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {RootStackParams} from "./types";

export default {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Privacy: '*',
    },
  },
} as LinkingOptions<RootStackParams>;
