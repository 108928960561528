import React from 'react';
import {useLegalConsts} from '../constants/LegalConsts';
import MarkDownViewer from "../components/general/MarkDownViewer";

export default function PrivacyPolicy(){
    const privacy = useLegalConsts()

    return(
        <MarkDownViewer markdown={privacy} />
    )
}
