import {useEffect, useState} from "react";

export let Privacy = ""

fetch(require('../../terms/Privacy.md')).then((r) => r.text()).then((v) => Privacy = v).catch(console.warn)

export function useLegalConsts() {
    const [state, setState] = useState(Privacy)

    useEffect(() => {
        if(Privacy.length == 0){
            fetch(require('../../terms/Privacy.md')).then((r) => r.text()).then((v) => {
                Privacy = v
                setState(Privacy)
            }).catch(console.warn)
        }
    }, [])

    return state
}
